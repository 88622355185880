import './App.css';

import './assets/css/sb-admin-2.css';
import './assets/css/b2b-custom.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/vendor/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Index from './layout/Index';
import { ToastContainer } from 'react-toastify';
import Provider from './utils/AppProvider';

function App() {
  return (
    <Router>
      <ToastContainer pauseOnFocusLoss={false} />
      <Provider>
        <Index></Index>
      </Provider>
    </Router>
  );
}

export default App;
