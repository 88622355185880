import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteCustomer, retriveAllCustomers } from "../../services/CustomerService";

const AllCustomers = () => {
    const [customers,setCustomers] = useState([]);

    useEffect(()=>{
        getAllCustomer();
    },[])

    const getAllCustomer = () =>{
        retriveAllCustomers()
            .then((response) => {
                if(response?.data)
                    setCustomers(response.data.data);
            })
            .catch((error) =>{
                console.log("Error:", error);
            })
    }

    const removeCustomer = (id) =>{
        deleteCustomer(id)
            .then((response) => {
                toast('Customer Deleted Successfully.',{type:'success'})
                let temp = customers.filter(c => c.id != id)
                setCustomers(temp);
            })
            .catch((error) =>{
                console.log("Error:", error);
            })
    }

    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800">All Customer </h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"> Listing </h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                       <table className="table upload-image" >
                        <thead>
                            <tr>
                                <th> Sr </th>
                                <th> Title </th>
                                <th> First Name </th>
                                <th> Last Name </th>
                                <th> Email </th>
                                <th> Status </th>
                                <th> Company </th>
                                <th> State </th>
                                <th> Country </th>
                                <th> Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customers.length > 0 ? 
                                    customers.map((customer,index) => {
                                        return (
                                            <tr key={index}>
                                                <td> { index + 1} </td>
                                                <td> { customer.title} </td>
                                                <td> { customer.first_name} </td>
                                                <td> { customer.last_name} </td>
                                                <td> { customer.email} </td>
                                                <td> { customer.status == 'confirmed' ? <span className="badge badge-warning"> {customer.status}</span> : <span className="badge badge-success"> {customer.status} </span>} </td>
                                                <td> { customer.company_name} </td>
                                                <td> { customer.state?.name} </td>
                                                <td> { customer.country?.name} </td>
                                                <td> 
                                                    <Link className="btn btn-warning" to={"/admin/customer/view/" + customer.id}> View </Link> &nbsp; 
                                                    {/* <button type="button" className="btn btn-danger" onClick={()=> removeCustomer(customer.id)}> Delete </button>  */}
                                                </td>
                                            </tr>
                                        );
                                    }) 
                                : <tr>
                                    <td colSpan={6} className="empty-customers"> No Data Available. </td>
                                </tr>
                            }
                            </tbody>
                       </table>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default AllCustomers;
