import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createCustomer, retriveSingleCustomer, updateCustomer } from "../../services/CustomerService";
import { retriveAllStates } from "../../services/StateService";

const EditCustomer = () => {
    const [states, setStates] = useState([]);
    const [customer, setCustomer] = useState({
        name:'',
        email:'',
        address:'',
        company_name:'',
        city:'',
        state_id:'',
        tax_id:'',
    });
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        console.log('params',params);
        if(params.id){
            getCustomer();
        }
        getStates();
    }, [])

    const getStates = () => {
        retriveAllStates()
            .then((response) => {
                if (response?.data)
                    setStates(response.data);
            })
            .catch((error) => {
                console.log("Error:", error);
            })
    }
    
    
    const getCustomer = () => {
        retriveSingleCustomer(params.id)
            .then((response) => {
                if (response?.data)
                    setCustomer(response.data.data);
            })
            .catch((error) => {
                console.log("Error:", error);
            })
    }

    const handleInputChange = (e) => {
        let {name, value} = e.target
        setCustomer({...customer,[name]:value});
    }

    const clearState = () => {
        setCustomer({
            name:'',
            email:'',
            password:'',
            address:'',
            company_name:'',
            city:'',
            state_id:'',
            tax_id:'',
        });
    }

    const handleSubmit = async() =>{
        if(validateInput()){
            await updateCustomer(customer).then((response)=>{
                
                toast('Customer Updated Successfully',{type: 'success'})

                clearState();
                navigate('/admin/approved-customers');
            }).catch((error) =>{
                console.log("e",error.response);
                if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            });
        }
    }

    const validateInput = () => {
            if(customer.name == ''){
                toast("Please enter name.",{
                    type:'error'
                });
                return false
            }
        if(customer.email == ''){
            toast("Please enter email.",{
                type:'error' 
            });
            return false
        }
        if(customer.password == ''){
            toast("Please enter password.",{
                type:'error'
            });
            return false
        }
        
        return true;
    }

    return (
        <div className="container-fluid mt-5">
            {/* <h1 className="h3 mb-2 text-gray-800">Add New Customer </h1> */}

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"> Edit Customer </h6>
                </div>
                <div className="card-body">
                    <form id="customer-form" className="">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="name">Name:</label>
                                <input type="text" name="name" value={customer.name} onChange={(e) => handleInputChange(e)} id="name" className="form-control" placeholder="Enter Full Name" />
                            </div>
                            <div className="col">
                            <label htmlFor="cmp_name">Company Name:</label>
                            <input type="text" id="cmp_name" name="company_name" value={customer.company_name} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Company Name" />
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col">
                            <label htmlFor="email">Email:</label>
                            <input type="text" id="email" name="email" value={customer.email} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Email Address" />
                            </div>
                            <div className="col">
                            <label htmlFor="address">Address</label>
                                <input type="text" id="address" name="address" value={customer.address} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Address" />
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col">
                            <label htmlFor="city"> City </label>
                            <input type="text" id="city" name="city" value={customer.city} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter City" />
                            </div>
                            <div className="col">
                            <label htmlFor="state_id">State</label>
                            <select name="state_id" id="state_id" value={customer.state_id} onChange={(e) => handleInputChange(e)} className="form-control">
                                    <option value="">Select State</option>
                                    {
                                        states.map((state,index) =>{
                                            return (
                                                <option key={index} value={state.id}> {state.name}</option>
                                            );
                                        })
                                    }
                                </select>    
                                
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-6">
                                <label htmlFor="tax_id">Tax ID:</label>
                                <input type="text" id="tax_id" name="tax_id" value={customer.tax_id} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Customer Tax ID" />
                            </div>
                            
                            
                        </div>

                        <div className="row mt-2">
                            <div className="col text-center">
                                <input type="button" className="btn btn-danger" onClick={() => clearState()} value="Clear" />
                                <input type="button" className="btn btn-success ml-2" value="Update" onClick={() => handleSubmit()} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default EditCustomer;
