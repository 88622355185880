import { useEffect } from "react";
import { useState } from "react";
import { retriveStats } from "../../services/AuthService";

const Home = () => {
  const [stats, setStats] = useState();

  useEffect(() => {
    getStats();
  },[]);

  const getStats = () => {
    retriveStats()
      .then((response) => {
        if (response?.data) {
          setStats(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      })
  }


  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
      </div>

      {/* Customer Stats */}
      <div className="row">
        <div className="col-xl-12 grid-margin stretch-card flex-column">
          <h5 className="mb-2 text-titlecase mb-2">Customer Statistics</h5>
          <div className="row">
            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted">Total Customers</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-"> {stats?.customer?.total_customers} </h3>
                    <h3 className="mb-"> 100 % </h3>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted">Approved Customers</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-"> { stats?.customer?.approved_customers } </h3>
                    <h3 className="mb-"> { (stats?.customer?.approved_customers * 100 / parseInt(stats?.customer?.total_customers)).toFixed(2)} %</h3>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted"> Rejected Customers</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-"> {stats?.customer?.rejected_customers} </h3>
                    <h3 className="mb-">{ (stats?.customer?.rejected_customers * 100 / parseInt(stats?.customer?.total_customers)).toFixed(2)} %</h3>
                  </div>

                </div>
              </div>
            </div>
            
            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted"> Pending Customers</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-"> {stats?.customer?.pending_customers} </h3>
                    <h3 className="mb-">{ (stats?.customer?.pending_customers * 100 / parseInt(stats?.customer?.total_customers)).toFixed(2)} %</h3>
                  </div>

                </div>
              </div>
            </div>

          </div> </div></div>

      {/* Order Stats */}
      <div className="row">
        <div className="col-xl-12 mt-4 grid-margin stretch-card flex-column">
          <h5 className="mb-2 text-titlecase mb-2">Order Statistics</h5>
          <div className="row mt-3">
            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted">Total Orders</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-"> { stats?.order?.total_orders} </h3>
                    <h3 className="mb-">100 %</h3>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted">Approved Orders</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-">{ stats?.order?.approved_orders}</h3>
                    <h3 className="mb-">  { stats?.order?.approved_orders ? (parseInt(stats?.order?.approved_orders) * 100 / parseInt(stats?.order?.total_orders)).toFixed(2) : 0} %</h3>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted"> Rejected Orders</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-">{ stats?.order?.rejected_orders}</h3>
                    <h3 className="mb-"> { stats?.order?.rejected_orders ?(stats?.order?.rejected_orders * 100 / parseInt(stats?.order?.total_orders)).toFixed(2) : 0} %</h3>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted"> Deliverd Orders</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-">{ stats?.order?.delivered_orders}</h3>
                    <h3 className="mb-"> { stats?.order?.delivered_orders ? (stats?.order?.delivered_orders * 100 / parseInt(stats?.order?.total_orders)).toFixed(2) : 0} %</h3>
                  </div>

                </div>
              </div>
            </div>
          </div></div></div>

      {/* Item Stats */}
      <div className="row">
        <div className="col-xl-12 mt-4 mb-4 grid-margin stretch-card flex-column">
          <h5 className="mb-2 text-titlecase mb-2">Item Statistics</h5>
          <div className="row mt-3">
            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted">Total Items</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-">{ stats?.item?.total_items}</h3>
                    <h3 className="mb-">100%</h3>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted">Active Items</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-">{ stats?.item?.active_items}</h3>
                    <h3 className="mb-"> { (stats?.item?.active_items * 100 / parseInt(stats?.item?.total_items)).toFixed(2)} %</h3>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 stretch-card grid-margin grid-margin-md-0 card-margin">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-between">
                  <p className="text-muted"> Inactive Items</p>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="mb-">{ stats?.item?.inactive_items}</h3>
                    <h3 className="mb-"> { (stats?.item?.inactive_items * 100 / parseInt(stats?.item?.total_items)).toFixed(2)} %</h3>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div></div></div>
  );
}


export default Home;