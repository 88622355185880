import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import { register } from "../../services/AuthService";

const Register = () => {
    const [states, setStates] = useState([]);
    const [state, setState] = useState({
        name:'',
        email:'',
        company_name:'',
        address:'',
        city:'',
        state_id:'',
        tax_id:'',
        password:''
    });

    const navigate = useNavigate();

    useEffect(()=>{
        // getStates();
    },[]);

   
    useEffect(()=>{
        let el = document.getElementsByTagName('body')[0];
        el.classList.toggle('bg-gradient-primary');

        return () => {
            el.classList.toggle('bg-gradient-primary');
        };
    },[]);

    const handleInputChange =(e)=>{
        let {name, value} = e.target;
        
        setState({...state, [name]:value});
    }   

    const handleSubmit = async () =>{
        if(validateInput()){
            await register(state).then((response)=>{
                localStorage.setItem('is_authenticated', 1);
                localStorage.setItem('token', response.data.data.token);
                
                toast('Customer Registered Successfully',{type: 'success'})

                navigate("/admin");
            }).catch((error) =>{
                if(error.response.status == 422)
                    toast(error.response.data.error,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            });
        }
    }

    const validateInput = () => {
        if(state.name == ''){
            toast("Please enter name.",{
                type:'error'
            });
            return false
        }
        if(state.email == ''){
            toast("Please enter email.",{
                type:'error' 
            });
            return false
        }
        if(state.password == ''){
            toast("Please enter password.",{
                type:'error'
            });
            return false
        }
        
        return true;
    }

    return (
        <div className="container">

        <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
                <div className="row">
                    <div className="col-lg-5 d-none d-lg-block bg-register-image"></div>
                    <div className="col-lg-7">
                        <div className="p-5">
                            <div className="text-center">
                                <h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
                            </div>
                            <form className="user">
                                <div className="form-group row">
                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <input type="text" className="form-control form-control-user" id="exampleFirstName"
                                            placeholder="First Name" />
                                    </div>
                                    <div className="col-sm-6">
                                        <input type="text" className="form-control form-control-user" id="exampleLastName"
                                            placeholder="Last Name" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control form-control-user" id="exampleInputEmail"
                                        placeholder="Email Address" />
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-6 mb-3 mb-sm-0">
                                        <input type="password" className="form-control form-control-user"
                                            id="exampleInputPassword" placeholder="Password" />
                                    </div>
                                    <div className="col-sm-6">
                                        <input type="password" className="form-control form-control-user"
                                            id="exampleRepeatPassword" placeholder="Repeat Password" />
                                    </div>
                                </div>
                                <Link className="btn btn-primary btn-user btn-block">
                                    Register Account
                                </Link>
                               
                            </form>
                            <hr />
                            <div className="text-center">
                                <a className="small" href="#forgot-password.html">Forgot Password?</a>
                            </div>
                            <div className="text-center">
                                <Link className="small" to="/login">Already have an account? Login!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    );
}

export default Register;