import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { retrivePendingOrders } from "../../services/OrderService";

const PendingOrders = () => {
    const [orders,setOrders] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(()=>{
        getOrders();
    },[page])

    const getOrders = () =>{
        retrivePendingOrders(page)
            .then((response) => {
                if(response?.data)
                    setOrders(response.data.data);
            })
            .catch((error) =>{
                console.log("Error:", error);
            })
    }

    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800">Pending Orders </h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="d-inline-block">
                        <h6 className="m-0 font-weight-bold text-primary"> Listing </h6>
                        </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                       <table className="table upload-image" >
                        <thead>
                            <tr>
                                {/* <th>  </th> */}
                                <th> Sr </th>
                                <th> Order ID </th>
                                <th> Customer ID </th>
                                <th> Customer Name </th>
                                <th> Email </th>
                                <th> Phone </th>
                                <th> Order Status </th>
                                <th> Fulfillment Status </th>
                                <th> Payment Type </th>
                                <th> Total </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders.length > 0 ? 
                                    orders.map((order,index) => {
                                        return (
                                            <tr key={index}>
                                                {/* <td> <input type="checkbox" name="" checked={selectedOrders.includes(order.id)} onChange={(e) => handleSelectItem(order.id ,e.target.checked)} id="" /> </td> */}
                                                <td> { index + 1} </td>
                                                <td> { order.id} </td>
                                                <td> { order.customer_id} </td>
                                                <td> { order.customer.name} </td>
                                                <td> { order.email} </td>
                                                <td> { order.billing_address.phone} </td>
                                                <td> { order.order_status == 'confirmed' ? <span className="badge badge-secondary">{order.order_status}</span> : order.order_status == 'approved' ? <span className="badge badge-success">{order.order_status}</span> : order.order_status == 'delivered' ? <span className="badge badge-primary">{order.order_status}</span> : <span className="badge badge-danger">{order.order_status}</span>} </td>
                                                <td> { order.fulfillment_status == null ? <span className="badge badge-secondary"> Not Fulfilled </span> : order.fulfillment_status == 'partial' ? <span className="badge badge-warning">{order.fulfillment_status}</span> : <span className="badge badge-success">{order.fulfillment_status}</span>} </td>
                                                <td> { order.payment_type} </td>
                                                <td> ${ order.total?.toFixed(2)} </td>
                                                
                                            </tr>
                                        );
                                    }) 
                                : <tr>
                                    <td colSpan={10} className="empty-customers"> No Data Available. </td>
                                </tr>
                            }
                            </tbody>
                       </table>

                       <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                                <li className={"page-item" + (page == 1 ? ' disabled' : '')}>
                                <a className="page-link" href="" onClick={(e) => { e.preventDefault(); setPage(page -1)}} tabIndex="-1">Previous</a>
                                </li>
                                
                                <li className="page-item active"><a className="page-link" href="#"> {page}</a></li>
                                
                                <li className={"page-item" + (orders?.length < 10 ? ' disabled' : '')}>
                                <a className="page-link" href="" onClick={(e) => { e.preventDefault(); setPage(page + 1)}}>Next</a>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default PendingOrders;
