import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveProduct, setItemPurchaseLimit, togglePromoteProduct } from "../../services/ProductService";

const Products = () => {
    const [products,setProducts] = useState();
    const [flag,setFlag] = useState(false);
    const [page, setPage] = useState(1);
    const [itemId, setItemId] = useState('');
    const [itemIndex, setItemIndex] = useState('');
    const [purchaseLimit, setPurchaseLimit] = useState('');

    useEffect(()=>{
        getProducts();
    },[flag, page]);

    const getProducts = () =>{
        retriveProduct(page).then((response)=>{
            setProducts(response.data.data);
        }).catch((error) =>{
            console.log("Error:", error);
        })
    }

    const togglePromoteItem = (item_id, action) =>{
        togglePromoteProduct(item_id,action   ? 1 : 0 ).then((response)=>{
            setFlag(!flag);
            toast("Status Changed Successfully.",{type:'success'});
        }).catch((error) =>{
            if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
        })
    }

    const handleSetPurchaseLimit = () => {
        
        setItemPurchaseLimit(itemId,purchaseLimit).then((response)=>{
            products[itemIndex]['purchase_limit'] = purchaseLimit;
            setProducts([...products]);
            setItemId('');
            setItemIndex('');
            setPurchaseLimit('');
            toast("Purchase Limit set Successfully.",{type:'success'});
        }).catch((error) =>{
            if(error.response.status == 422)
                toast(error.response.data.message,{type: 'error'})
            else if(error.response.status == 500)
                toast(error.response.data.message,{type: 'error'})
            else    
                toast("Something went wrong", {type: 'error'});
        })
    }

    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800">Products </h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"> Product Listing</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Sr No</th>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <th>Brand</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Prchase Limit</th>
                                    <th>Promote</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    products?.map((product, index)=>{
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td> {product.item_name} </td>
                                                <td> {product.category?.category_name} </td>
                                                <td> {product.brand?.brand_name} </td>
                                                <td> {product.price } </td>
                                                <td> {product.stock} </td>
                                                <td> 
                                                    <div className="row">
                                                        <div className="col">{product.purchase_limit} </div>  
                                                        <div className="col">
                                                            
                                                            <button className="btn btn-info btn-small" onClick={() => { setItemId(product.id); setItemIndex(index); setPurchaseLimit(product.purchase_limit)}}>Set</button> 
                                                        </div>
                                                    </div>
                                                </td>
                                                <td> 
                                                    <label className="switch"> 
                                                        <input type="checkbox" onChange={() => togglePromoteItem(product.id, !product.is_promoted)} checked={product.is_promoted} />
                                                        <span className="slider round"></span>
                                                    </label>    
                                                </td>
                                                <td> 
                                                    <Link to={"/admin/products/" + product.id +"/upload-images"} className="btn btn-primary" ><i className="fas fa-fw fa-upload"></i> Upload Image</Link>
                                                    
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                                <li className={"page-item" + (page == 1 ? ' disabled' : '')}>
                                <a className="page-link" href="" onClick={(e) => { e.preventDefault(); setPage(page -1)}} tabIndex="-1">Previous</a>
                                </li>
                                
                                <li className="page-item active"><a className="page-link" href="#"> {page}</a></li>
                                
                                <li className={"page-item" + (products?.length < 10 ? ' disabled' : '')}>
                                <a className="page-link" href="" onClick={(e) => { e.preventDefault(); setPage(page + 1)}}>Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>


            {
                itemId ?
                <div className="modal fade show" style={{display:'block'}} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Item Purchase Limit</h5>
                      <button type="button" onClick={() => setItemId('')} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <div className="row align-items-baseline">
                          <label htmlFor="purchse_limit" className="font-weight-bold col-sm-3">Enter Limit:</label>
                          <input type="number" id="purchse_limit" min={1} max={100} value={purchaseLimit} onChange={(e) => setPurchaseLimit(e.target.value)} className="form-control col-sm-4" />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" onClick={() => setItemId('')} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" onClick={() => handleSetPurchaseLimit()} className="btn btn-primary">Set</button>
                    </div>
                  </div>
                </div>
              </div>
                : null
            }
        </div>
    );
}


export default Products;