import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../utils/AppProvider";

const Header = () => {
    const location = useLocation();
    const [customerMenu,setCustomerMenu] = useState(false);
    const [orderMenu,setOrderMenu] = useState(false);
    const [agentMenu,setAgentMenu] = useState(false);
    const { admin_access } = useContext(AppContext);
    const customer_paths =['/admin/all-customers','/admin/add-customer','/admin/pending-application','/admin/approved-customers','/admin/rejected-customers'];
    const order_paths =['/admin/orders','/admin/pending-orders','/admin/order-history'];
    const agent_paths =['/admin/agents'];

   return (
     
      <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

          
          <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/admin">
              <div className="sidebar-brand-icon rotate-n-15">
                  <i className="fas fa-laugh-wink"></i>
              </div>
              <div className="sidebar-brand-text mx-3">B2B Agent </div>
          </Link>

          
          <hr className="sidebar-divider my-0" />

          
          <li className={"nav-item" + ( location.pathname == '/admin' ? ' active' : '')}>
              <Link className="nav-link" to="/admin">
                  <i className="fas fa-fw fa-tachometer-alt"></i>
                  <span>Dashboard</span></Link>
          </li>

          {
                admin_access ? 
                <li className={"nav-item" + (  agent_paths.includes(location.pathname) ? ' active' : '')}>
                    <Link className={"nav-link"} to="/admin/agents">
                        {/* <i className="fas fa-fw fa-wrench"></i> */}
                        <i className="fas fa-solid fa-user-secret"></i>
                        <span> Agents </span>
                    </Link>
                </li>
                : null
            }
            
            
          <li className={"nav-item" + ( location.pathname == '/admin/products' ? ' active' : '')}>
                <Link className="nav-link" to="/admin/products">
              <i className="fas fa-fw fa-list"></i>
                  <span> Products </span>
                </Link>
          </li>
            
            <li className={"nav-item" + (  customer_paths.includes(location.pathname) ? ' active' : '')}>
                <a className={"nav-link" + (!customerMenu ? " collapsed"  :'')} onClick={(e) => { e.preventDefault(); setCustomerMenu(!customerMenu)}} href="" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
                    {/* <i className="fas fa-fw fa-wrench"></i> */}
                    <i className="fas fa-solid fa-user"></i>
                    <span> Customers </span>
                </a>
                <div id="collapseUtilities" className={"collapse" + (customerMenu ? ' show' : '') } aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        {/* <h6 className="collapse-header">Custom Utilities:</h6> */}
                        <Link className="collapse-item" to="/admin/all-customers"> All Customers </Link>
                        <Link className="collapse-item" to="/admin/add-customer"> Add New Customer </Link>
                        <Link className="collapse-item" to="/admin/pending-application"> Pending Application </Link>
                        <Link className="collapse-item" to="/admin/approved-customers"> Approved Customer </Link>
                        <Link className="collapse-item" to="/admin/rejected-customers"> Rejected Customer </Link>
                    </div>
                </div>
            </li>
            
            <li className={"nav-item" + (  order_paths.includes(location.pathname) || location.pathname.startsWith('/admin/orders/manage/') ? ' active' : '')}>
                <a className={"nav-link" + (!orderMenu ? " collapsed"  :'')} onClick={(e) => { e.preventDefault(); setOrderMenu(!orderMenu);}} href="" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
                    {/* <i className="fas fa-fw fa-wrench"></i> */}
                    <i className="fas fa-solid fa-shopping-cart"></i>
                    <span> Order </span>
                </a>
                <div id="collapseUtilities" className={"collapse" + (orderMenu ? ' show' : '') } aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        {/* <h6 className="collapse-header">Custom Utilities:</h6> */}
                        <Link className="collapse-item" to="/admin/orders"> Orders </Link>
                        <Link className="collapse-item" to="/admin/pending-orders"> Pending Orders </Link>
                        <Link className="collapse-item" to="/admin/order-history"> History </Link>
                    </div>
                </div>
            </li>
      </ul>
   );
}

export default Header;