import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { approveCustomer, rejectCustomer, retrivePendingApplication } from "../../services/CustomerService";

const PendingAplications = () => {
    const [pendingApplication, setPendingApplication] = useState([]);
    const [poscustomers, setPosCustomers] = useState([]);
    const [posCustomerId, setPosCustomerId] = useState([]);
    const [message, setMessage] = useState('');
    const [approvalLoaderId, setApprovalLoaderId] = useState('');
    const [rejectionLoaderId, setRejectionLoaderId] = useState('');
    const [posFilter, setPosFilter] = useState('');
    const [filteredCustomers, setFilteredCustomers] = useState([]);

    useEffect(() => {
        getPendingApplication();

        return () => {
            setPosCustomerId('');
        }
    }, [])

    const getPendingApplication = () => {
        retrivePendingApplication()
            .then((response) => {
                if (response?.data) {
                    setPendingApplication(response.data.data.customers);
                    setPosCustomers(response.data.data.pos_customers);
                }
            })
            .catch((error) => {
                console.log("Error:", error);
            })
    }

    const handleApproveCustomer = (id, index = '') => {
        if (posCustomerId == '') {
            toast("Please Select POS Customer", { type: 'error' })
            return true;
        }

        setApprovalLoaderId(id);
        approveCustomer(id, posCustomerId)
            .then((response) => {
                if (response?.data) {
                    let temp = pendingApplication.filter(c => c.id != id)

                    setPendingApplication(temp);
                    setPosCustomerId('');
                    setMessage('');
                    setPosFilter('');
                    setFilteredCustomers([]);
                    document.getElementById('pos_cust_btn' + index).innerText = "Select POS Customer"

                    toast("Customer Approved Successfully.", { type: 'success' });
                }
            })
            .catch((error) => {
                toast("Something went wrong while approving customer.", { type: 'error' })
                console.log("Error:", error);
            })
            .finally(() => {
                setApprovalLoaderId('');
            })
    }

    const handleRejectCustomer = (id) => {
        if (message == '') {
            toast("Please Enter Rejection Message", { type: 'error' })
            return true;
        }

        setRejectionLoaderId(id);
        rejectCustomer(id, message)
            .then((response) => {
                if (response?.data) {
                    let temp = pendingApplication.filter(c => c.id != id)

                    setPendingApplication(temp);
                    setPosCustomerId('');
                    setMessage('');

                    toast("Customer Rejected Successfully.", { type: 'success' });
                }
            })
            .catch((error) => {
                toast("Something went wrong while approving customer.", { type: 'error' })
                console.log("Error:", error);
            })
            .finally(() => {
                setRejectionLoaderId('');
            });
    }

    const handleSelectPOSCust = (e, index) => {
        setPosCustomerId(e.target.value)
        document.getElementsByName('pos_customers' + index)[0].classList.toggle("show")
        document.getElementById('pos_cust_btn' + index).innerText = poscustomers.filter((c) => c.id == e.target.value)[0].customer_name
    }

    const filterPOSCustomers = (e) => {
        setPosFilter(e.target.value);
        let temp = [];
        temp = poscustomers.filter(c => c.customer_name.includes(e.target.value) || c.customer_name.includes(e.target.value.toUpperCase()))
        setFilteredCustomers(temp);

    }
    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800"> Pending Applications </h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"> Customer Details </h6>
                </div>
                <div className="card-body">
                    <div>
                        {/* <div className="row">
                            <div className="col">
                                <label htmlFor=""> Name </label>
                                <label htmlFor=""> ABCD </label>
                            </div>
                        </div> */}

                        <table className="table pending-appication-table">
                            <tbody>
                                {
                                    pendingApplication.length > 0 ? pendingApplication.map((customer, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <th border="0"> Name </th>
                                                    <td> {customer.name} </td>
                                                    <th> Email </th>
                                                    <td> {customer.email} </td>
                                                    <th> Company Name </th>
                                                    <td> {customer.company_name} </td>
                                                </tr>

                                                <tr>
                                                    <th> Address </th>
                                                    <td> {customer.address} </td>
                                                    <th> City </th>
                                                    <td> {customer.city} </td>
                                                    <th> State </th>
                                                    <td> {customer?.state?.name} </td>
                                                </tr>

                                                <tr>
                                                    <th> Approve Customer </th>
                                                    <th>
                                                        <div>
                                                            <div className="dropdown">
                                                                <button onClick={(e) => document.getElementsByName('pos_customers' + index)[0].classList.toggle("show")} id={"pos_cust_btn" + index} value={customer.id} className="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> Select POS Customer </button>
                                                                <div id="myDropdownmenu" name={"pos_customers" + index} className="dropdown-content">
                                                                    <input type="text" placeholder="Search.." id="myInput" value={posFilter} onChange={(e) => filterPOSCustomers(e)} />
                                                                    {
                                                                        posFilter != '' ? filteredCustomers.length > 0 ?
                                                                            <>
                                                                                <option className="dropdown-item" value=''>Select Customer</option>
                                                                                {
                                                                                    filteredCustomers.map((c, _index) => {
                                                                                        return (
                                                                                            <option key={_index} onClick={(e) => handleSelectPOSCust(e, index)} className="dropdown-item" value={c.id}>{c.customer_name}</option>
                                                                                        );
                                                                                    })}
                                                                            </>
                                                                            : <div> <h6 className="dropdown-item" value=''>No Customer Available</h6> </div>
                                                                            :
                                                                            poscustomers.map((c, _index) => {
                                                                                return (
                                                                                    <option key={_index} onClick={(e) => handleSelectPOSCust(e, index)} className="dropdown-item" value={c.id}>{c.customer_name}</option>
                                                                                );
                                                                            })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <select onChange={(e) => setPosCustomerId(e.target.value) } className="form-control" name={"pos_customers" + index} id={"pos_cust_" + index }>
                                                            <input type="text" name="" id="" placeholder="Search" />
                                                            <option value="" selected disabled>Select POS Customer</option>
                                                            {
                                                                poscustomers.map((c,index) => {
                                                                    return (
                                                                        <option value={c.id}>{c.customer_name}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select> */}
                                                    </th>
                                                    <td colSpan={2} style={{ textAlign: 'center' }}>
                                                        <a href="javascript:void(0)" onClick={() => handleApproveCustomer(customer.id, index)} className="btn btn-success btn-icon-split">
                                                            <span className="icon text-white-50">
                                                                {
                                                                    (customer.id == approvalLoaderId) ?
                                                                        <img src="/images/loader.gif" width="20" alt="" />
                                                                        : <i className="fas fa-check"></i>
                                                                }
                                                            </span>
                                                            <span className="text"> Approve </span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}> <hr style={{ width: '70%' }} /></td>
                                                </tr>

                                                <tr>
                                                    <th> Reject Customer </th>
                                                    <th>
                                                        <textarea onChange={(e) => setMessage(e.target.value)} className="form-control" name={"message_" + index} id={"message" + index} cols="30" rows="2">

                                                        </textarea>
                                                    </th>
                                                    <td colSpan={2} style={{ textAlign: 'center' }}>
                                                        <a href="javascript:void(0)" onClick={() => handleRejectCustomer(customer.id)} className="btn btn-danger btn-icon-split">
                                                            <span className="icon text-white-50">
                                                                {
                                                                    (customer.id == rejectionLoaderId) ?
                                                                        <img src="/images/loader.gif" width="20" alt="" />
                                                                        : <i className="fas fa-times"></i>
                                                                }
                                                                {/* <i className="fas fa-times"></i> */}
                                                            </span>
                                                            <span className="text"> Reject </span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr style={{ borderBottom: '1px solid #b7b7b7' }}  > <td></td></tr>
                                            </>
                                        );
                                    })
                                        : <tr>
                                            <td align="center"> <h5>No Aplication Available</h5> </td>
                                        </tr>
                                }

                            </tbody>
                        </table>

                    </div>
                    {/* <hr /> */}
                </div>
            </div>
        </div>
    );
}


export default PendingAplications;
