import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveApprovedCustomers = async () => {
    let token = await localStorage.getItem('agent-token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get('/agent/approved-customers', { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveAllCustomers = async () => {
    let token = await localStorage.getItem('agent-token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get('/agent/all-customers', { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveRejectedCustomers = async () => {
    let token = await localStorage.getItem('agent-token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get('/agent/rejected-customers', { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retrivePendingApplication = async () => {
    let token = await localStorage.getItem('agent-token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get('/agent/pending-applications', { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const approveCustomer = async (id, pos_customer_id) => {
    let token = await localStorage.getItem('agent-token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/approve-customer?id=${id}&pos_id=${pos_customer_id}`, { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


const retriveSingleCustomer = async (id) => {
    let token = await localStorage.getItem('agent-token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/single-customer?id=${id}`, { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const rejectCustomer = async (id, message) => {
    let token = await localStorage.getItem('agent-token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }
    let data = {
        id: id,
        message: message
    }
    return new Promise(function (resolve, reject) {
        axios.post('/agent/reject-customer', data, { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const createCustomer = async (customer) => {
    let token = await localStorage.getItem('agent-token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.post('/agent/create-customer', { data: customer }, { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const updateCustomer = async (customer) => {
    let token = await localStorage.getItem('agent-token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.post('/agent/update-customer', { data: customer }, { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const deleteCustomer = async (id) => {
    let token = await localStorage.getItem('agent-token')
    let headers = {
        'Authorization': `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.delete(`/agent/delete-customer?id=${id}`, { headers: headers })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


export { 
    retriveApprovedCustomers, 
    retrivePendingApplication, 
    approveCustomer, 
    rejectCustomer, 
    createCustomer, 
    retriveRejectedCustomers, 
    retriveSingleCustomer,
    updateCustomer,
    deleteCustomer,
    retriveAllCustomers
};