import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveItem, saveItemImages } from "../../services/ProductService";
import Webcam from "react-webcam";

const UploadImage = () => {
    const [product,setProduct] = useState()
    const [test,setTest] = useState('')
    const [showWebCam,setShowWebCam] = useState(false)
    const [images,setImages] = useState([])
    const [capturedImages,setCapturedImages] = useState([])
    const params = useParams();
    

    const webcamRef = React.useRef(null);

  const handleCapture = () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImages([...capturedImages, imageSrc]);
      setShowWebCam(false);
    }

    useEffect(() => {
        
    },[capturedImages]);


    useEffect(()=>{
        retriveItem(params.id).then((response)=>{
            setProduct(response.data.data);
        }).catch((error) =>{
            console.log("Error:", error);
        })
    },[]);

    const saveImages = () =>{
        if(images?.length <= 0 && capturedImages.length <= 0){
            toast('Please Select Images',{type: 'error'})
            return true;
        }
        let formdata = new FormData();

        for (let i = 0 ; i < images.length ; i++) {
            formdata.append("images[]", images[i]);
        }
        
        for (let i = 0 ; i < capturedImages.length ; i++) {
            formdata.append("captured_images[]", capturedImages[i]);
        }

        formdata.append('item_id',params.id) ;

        saveItemImages(params.id, formdata).then((response)=>{
            if(response.data.data){
                setProduct({...product, ['images'] : response.data.data});
                setImages([]);
                setCapturedImages([]);
                toast('Image Uploaded Successfully.',{type:'success'});
                document.getElementById("item-images").value = "";
            }
        }).catch((error) =>{
            toast("Error while uploading image",{type:'error'});
            console.log("Error:", error);
        })
    }

    const handleImageChange = (e) =>{
        setImages(e.target.files);
    }

    return (
        <div className="container-fluid mt-5">
            {/* <h1 className="h3 mb-2 text-gray-800">Products </h1> */}

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"> Upload Image for a Product</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                       <table className="table upload-image" >
                        <tbody>
                            <tr>
                                <td> Name </td>
                                <td> {product?.item_name}</td>
                            </tr>
                            <tr>
                                <td> Previous Images </td>
                                <td> 
                                    <div className="row">

                                    
                                    { product?.images?.map((img, index)=>{
                                        return (
                                            <div className="col col-sm-2 pt-2" style={{maxWidth:'12%'}} key={index}>
                                                <img width="150" height="150" src={process.env.REACT_APP_BASE_URL + '/products/' + img} alt="" />
                                            </div>
                                        )
                                }) }
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td> Select Images </td>
                                <td> 
                                    <div className="row">
                                        <input type="file" accept="image/*" multiple onChange={(e)=> handleImageChange(e)} name="" placeholder="Image" className="form-control w-50" id="item-images" /> 
                                        
                                        <button onClick={() => saveImages()} className="btn btn-primary ml-3" > Save Images </button> 
                                    </div>
                                    <div className="row">
                                        {images ? Object.entries(images).map((image,index) => {
                                            return(
                                                <div className="col col-sm-2 pt-2 mr-1" style={{maxWidth:'12%'}} key={index}>
                                                    <img width="150" height="150" src={URL.createObjectURL(image[1])} alt="" />
                                                </div>
                                            );
                                            
                                            
                                    
                                        }) : null}

                                    </div>
                                    <div className="row mt-2">
                                        {
                                            (!showWebCam) ?
                                            <>
                                                <div className="col-2 d-flex align-items-center">
                                                    <button className="btn btn-info" style={{height:"max-content"}}
                                                    onClick={() => setShowWebCam(true)}>
                                                    Capture Photo</button>
                                                </div>
                                                {
                                                    capturedImages.map((image, index) => {
                                                        return <img key={index} width="150" height="150"  style={{marginRight:'10px'}}   src={image} alt="" />
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                <div className="d-inline-block" style={{width:'fit-content'}}>
                                                    <Webcam
                                                        audio={false}
                                                        height={200}
                                                        ref={webcamRef}
                                                        screenshotFormat="image/jpeg"
                                                        width={220}
                                                    />
                                                </div>
                                                <div className="col-6 d-flex align-items-center">
                                                    <button className="btn btn-info" style={{height:"max-content"}}
                                                    onClick={(e)=>{e.preventDefault();handleCapture();}}>
                                                    Capture</button>
                                                    
                                                    <button className="btn btn-danger ml-1" style={{height:"max-content"}}
                                                    onClick={(e)=> setShowWebCam(false)}>
                                                    Close</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                       </table>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default UploadImage;