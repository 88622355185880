import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { approveOrderItems, changeOrderStatus, pushOrderToPOS, rejectOrderItems, retriveOrderById } from "../../services/OrderService";
import { stateByIds } from "../../services/StateService";

const ManageOrder = () => {
    const [order,setOrder] = useState({});
    const [orderStates, setOrderStates] = useState([]);
    const [selectedItem, setSelectedItems] = useState([]);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(async()=>{
        let id = params.id;
        if(id)  await getOrder(id);

        return () => {
            setOrder({});
            setOrderStates([]);
        }
    },[ params.id])

    useEffect(async() => {
        if(order){
          let ids = [order.billing_address?.state_id,order.shipping_address?.state_id].join(",");
          await stateByIds(ids).then((response) => {
    
            if (response?.data?.data) setOrderStates(response.data.data);
          }).catch((error) => {
              toast("Something went wrong", { type: 'error' });
          });
        }
      },[order, params.id]);

    const getOrder = (id) =>{
        retriveOrderById(id)
            .then((response) => {
                if(response?.data)
                    setOrder(response.data.data);
            })
            .catch((error) =>{
                console.log("Error:", error);
            })
    }

    const handleSelectItem = (id, value) => {
        if(value === true){
            setSelectedItems([...selectedItem, id]);
        }else{
            setSelectedItems(selectedItem.filter((el) => el != id));
        }
    }

    const handleApproveItem = () => {
        if(selectedItem.length <= 0){
            toast("Please select item.",{type:'error'});
            return true;
        }
        let item_ids = selectedItem.join(",");
        approveOrderItems(order.id, item_ids)
            .then((response) => {
                if(response?.data){
                    setOrder(response.data.data);
                    setSelectedItems([]);
                }
                toast("Order Items Approved Successfully.",{type:'success'});
            })
            .catch((error) =>{
                console.log("Error:", error);
                if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            })
    }
    
    const handleRejectItem = () => {
        if(selectedItem.length <= 0){
            toast("Please select item.",{type:'error'});
            return true;
        }
        let item_ids = selectedItem.join(",");
        rejectOrderItems(order.id, item_ids)
            .then((response) => {
                if(response?.data){
                    setOrder(response.data.data);
                    setSelectedItems([]);
                }
                toast("Order Items Rejected Successfully.",{type:'success'});
            })
            .catch((error) =>{
                console.log("Error:", error);
                if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            })
    }

    const handleOrderStatusChange = (status) => {
        changeOrderStatus(order.id, status)
            .then((response) => {
                if(response?.data){
                    setOrder({...order,['order_status']: status});
                    toast("Order Status Changed Successfully.",{type:'success'});
                }
            })
            .catch((error) =>{
                console.log("Error:", error);
                if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            })
    }

    const handlePushOrderToPOS = () =>{
        pushOrderToPOS(order.id)
            .then((response) => {
                if(response?.data){
                    setOrder({...order,['sync_with_pos']: true});
                    toast("Order Pushed to POS Successfully.",{type:'success'});
                }
            })
            .catch((error) =>{
                console.log("Error:", error);
                if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            })
    }
    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800 d-inline-block"> Manage Order </h1>
            <button className="btn btn-info float-right" onClick={() => navigate('/admin/orders')}>Back</button>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="d-inline-block">

                    <h6 className="m-0 font-weight-bold text-primary"> Order Details </h6>
                    </div>
                    <div className="d-inline-block float-right">
                        
                        <button className="btn btn-success" onClick={() => handleOrderStatusChange('approved')}>Approve</button>
                        <button className="btn btn-danger mr-1 ml-1" onClick={() => handleOrderStatusChange('rejected')}>Reject</button>
                        <button className="btn btn-primary mr-1 ml-1" onClick={() => handleOrderStatusChange('delivered')}>Mark Delivered</button>
                        <button className="btn btn-info" disabled={order.sync_with_pos == 1} onClick={() => handlePushOrderToPOS('delivered')}>Push to POS</button>
                    </div>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col">
                                <label className="font-weight-bold" htmlFor="">Email:</label> &nbsp;
                                {order.email}
                            </div>
                            <div className="col">
                                <label className="font-weight-bold" htmlFor="">Phone:</label> &nbsp;
                                {order.phone}
                            </div>
                            <div className="col">
                                <label className="font-weight-bold" htmlFor="">Customer Id:</label> &nbsp;
                                    { order.customer_id }
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <label className="font-weight-bold" htmlFor="">Sub Total:</label> &nbsp;
                                {order.subtotal}
                            </div>
                            <div className="col">
                                <label className="font-weight-bold" htmlFor="">Total:</label> &nbsp;
                                {order.total}
                            </div>
                            <div className="col">
                                <label className="font-weight-bold" htmlFor="">Payment Type:</label> &nbsp;
                                    {order.payment_type}
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <label className="font-weight-bold" htmlFor="">Order Status:</label> &nbsp;
                                { order.order_status == 'confirmed' ? <span className="badge badge-secondary">{order.order_status}</span> : order.order_status == 'approved' ? <span className="badge badge-success">{order.order_status}</span> :  order.order_status == 'delivered' ? <span className="badge badge-primary">{order.order_status}</span> : <span className="badge badge-danger">{order.order_status}</span>}
                            </div>
                            <div className="col">
                                <label className="font-weight-bold" htmlFor="">Fulfillment Status:</label> &nbsp;
                                
                                { order.fulfillment_status == null ? <span className="badge badge-secondary"> Not Fulfilled </span> : order.fulfillment_status == 'partial' ? <span className="badge badge-warning">{order.fulfillment_status}</span> : <span className="badge badge-success">{order.fulfillment_status}</span>}
                            </div>
                            <div className="col">
                                <label className="font-weight-bold" htmlFor="">Sync with POS:</label> &nbsp;
                                {order.sync_with_pos == 1 ? <span className="badge badge-success">Yes</span> :<span className="badge badge-danger">No</span>}
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-4">
                                <label className="font-weight-bold" htmlFor="">Order Date:</label> &nbsp;
                                    123
                            </div>
                            <div className="col-8">
                                <label className="font-weight-bold" htmlFor="">Note:</label> &nbsp;
                                Tusted Returning Customer
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col">
                                <div className="d-inline-block align-top">
                                    <label className="font-weight-bold" htmlFor="">Billing Address:</label> &nbsp;
                                </div>
                                <div className="d-inline-block">
                                    {order.billing_address?.name} <br />
                                    {order.billing_address?.company_name} <br />
                                    {order.billing_address?.address} <br />
                                    {order.billing_address?.city} <br />
                                    {orderStates[order.billing_address?.state_id]} <br />
                                    {order.billing_address?.pincode}
                                </div>
                            </div>
                            
                            <div className="col">
                                <div className="d-inline-block align-top">
                                    <label className="font-weight-bold" htmlFor="">Shipping Address:</label> &nbsp;
                                </div>
                                <div className="d-inline-block">
                                    {order.shipping_address?.name} <br />
                                    {order.shipping_address?.company_name} <br />
                                    {order.shipping_address?.address} <br />
                                    {order.shipping_address?.city} <br />
                                    {orderStates[order.shipping_address?.state_id]} <br />
                                    {order.shipping_address?.pincode}
                                </div>
                            </div>
                            
                            <div className="col"></div>
                        </div>
                        <h5 className="font-weight-bold mt-3">Order Items:</h5>
                        <table className="table pending-appication-table " style={{width:'60%'}}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Available Quantity</th>
                                    <th>Fulfillment Status</th>
                                    <th style={{width:'20%'}}>
                                        <button className="btn btn-success" onClick={() => handleApproveItem()}>Approve</button>
                                        <button className="btn btn-danger ml-2" onClick={() => handleRejectItem()}>Reject</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    order.line_items?.length > 0 ? order.line_items.map((item, index) => {
                                        return (
                                            <tr key={index} style={{borderBottom:'1px solid #e3e6f0'}}>
                                                <th border="0"> <input type="checkbox" name="" checked={selectedItem.includes(item.id)} onChange={(e) => handleSelectItem(item.id ,e.target.checked)} id="" /> </th>
                                                <td> {item.item.item_name} </td>
                                                <td> {item.quantity} </td>
                                                <td> {item.item.stock} </td>
                                                <td> {item.fulfillment_status == null ? 'Pending' : item.fulfillment_status} </td>
                                            </tr>
                                        );
                                    })
                                        : <tr>
                                            <td align="center"> <h5>No Aplication Available</h5> </td>
                                        </tr>     
                                }

                            </tbody>
                        </table>

                    </div>
                    {/* <hr /> */}
                </div>
            </div>
        </div>
    )
}


export default ManageOrder;
