import { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAgent, retriveAllRoles, retriveSingleAgent, updateAgent } from "../../services/AgentService";
import { AppContext } from "../../utils/AppProvider";

const EditAgent = () => {
    const [roles, setRoles] = useState([]);
    const [agent, setAgent] = useState({
        username:'',
        firstname:'',
        lastname:'',
        email:'',
        gender:'',
        dob:'',
        mobile:'',
        address:'',
        city:'',
        state:'',
        country:'',
        postcode:'',
        role_id:'',
        status:'',
    });
    const navigate = useNavigate();
    const {admin_access} = useContext(AppContext);
    const params = useParams();

    useEffect(() => {
        if(admin_access != true)
            navigate('/admin');
        else{
            getRoles();
            if(params.id)  getAgent();
        }
    }, [])

    const getRoles = () => {
        retriveAllRoles()
            .then((response) => {
                if (response?.data)
                    setRoles(response.data.data);
            })
            .catch((error) => {
                console.log("Error:", error);
            })
    }
    
    const getAgent = () => {
        retriveSingleAgent(params.id)
            .then((response) => {
                if (response?.data){
                    delete response.data.data.password;
                    setAgent(response.data.data);
                }
            })
            .catch((error) => {
                console.log("Error:", error);
            })
    }

    const handleInputChange = (e) => {
        let {name, value} = e.target
        setAgent({...agent,[name]:value});
    }

    const clearState = () => {
        setAgent({
            username:'',
            firstname:'',
            lastname:'',
            email:'',
            gender:'',
            dob:'',
            mobile:'',
            password:'',
            address:'',
            city:'',
            state:'',
            country:'',
            postcode:'',
            role_id:'',
            status:'',
        });
    }

    const handleSubmit = async() =>{
            await updateAgent(agent).then((response)=>{
                toast('Agent Updated Successfully',{type: 'success'})

                navigate('/admin/agents');
            }).catch((error) =>{
                console.log("Error",error);
                if(error.response.status == 422)
                    toast(error.response.data.error,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            });
    }

    return (
        <div className="container-fluid mt-5">
            {/* <h1 className="h3 mb-2 text-gray-800">Add New Customer </h1> */}

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"> Edit Agent </h6>
                </div>
                <div className="card-body">
                    <form id="agent-form" className="">
                        <div className="row">
                            <div className="col">
                                <input type="text" name="username" autoComplete="false" value={agent.username} onChange={(e) => handleInputChange(e)} id="name" className="form-control" placeholder="Enter Username" />
                            </div>
                            <div className="col">
                                <input type="text" name="firstname" value={agent.firstname} onChange={(e) => handleInputChange(e)} id="name" className="form-control" placeholder="Enter First Name" />
                            </div>
                        </div>
                        
                        <div className="row  mt-2">
                            <div className="col">
                                <input type="text" name="lastname" value={agent.lastname} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Last Name" />
                            </div>
                            <div className="col">
                                <input type="text" name="mobile" value={agent.mobile} onChange={(e) => handleInputChange(e)} id="name" className="form-control" placeholder="Enter Mobile" />
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col">
                            <input type="text" name="email" value={agent.email} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Email Address" />
                            </div>
                            <div className="col">
                                <input type="password" name="password" value={agent.password} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Password (Leave blank to do not change)" />
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col">
                            <input type="text" name="address" value={agent.address} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Address" />
                            </div>
                            <div className="col">
                                <input type="text" name="city" value={agent.city} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter City" />
                                
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col">
                                <input type="text" name="state" value={agent.state} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter State" />
                            </div>
                            
                            <div className="col">
                                <input type="text" name="country" value={agent.country} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Country" />
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col">
                                <input type="text" name="postcode" value={agent.postcode} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Postcode" />
                            </div>
                            <div className="col">
                                <label htmlFor=""> Gender </label>
                                <div>
                                    <input type="radio" id="male_gender" name="gender" checked={agent.gender == 'male'} value="male" onChange={(e) => handleInputChange(e)} className="" />
                                    <label className="ml-1" htmlFor="male_gender">Male</label>

                                    <input type="radio" id="female_gender" name="gender" checked={agent.gender == 'female'} value="female" onChange={(e) => handleInputChange(e)} className="ml-4" />
                                    <label htmlFor="female_gender" className="ml-1"> Female</label>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col">
                               <select name="role_id" value={agent.role_id} onChange={(e) => handleInputChange(e)} className="form-control" id="role_dropdown">
                                    <option disabled value="">Select Role</option>
                                    {
                                        roles.map((role, index) => {
                                            return <option key={index}   value={role.id}>{role.role_name}</option>
                                        })
                                    }
                                    </select>
                            </div>
                            <div className="col">
                                <select name="status" value={agent.status} onChange={(e) => handleInputChange(e)} className="form-control" id="status_dropdown">
                                    <option disabled value="">Select Status</option>
                                    <option value="1"> Active </option>
                                    <option value="0"> Deactive </option>
                                </select>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col">
                                <input type="date" name="dob" value={agent.dob} max={new Date().toISOString().slice(0, 10)} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Select Date of Birth" />
                            </div>
                            <div className="col">
                                
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col text-center">
                                <input type="button" className="btn btn-danger" onClick={() => clearState()} value="Clear" />
                                <input type="button" className="btn btn-success ml-2" value="Update" onClick={() => handleSubmit()} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default EditAgent;
