import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveSingleCustomer } from "../../services/CustomerService";

const ViewCustomer = () => {
    const [customer,setCustomer] = useState();
    const params = useParams();


    useEffect(async() => {
        console.log('params',params);
        
        if(params.id){
            getCustomer();
        }
    }, [])
    
    useEffect(() =>{

    },[]);

    const getCustomer = () => {
        retriveSingleCustomer(params.id)
        .then((response)=>{
            console.log("Response", response);
            setCustomer(response.data.data);
        }).catch((error) => {
                console.log("Error",error);
        });
    };

    // const getCountries = async () => {
    //     await getAllCountries().then((response) => {
    //         setCountries(response.data.data);
    //     }).catch((error) => {

    //     });
    // }

    // const getStates = async (country_id = '') => {
    //     await retriveAllStates(country_id).then((response) => {
    //         setStates(response.data);
    //     }).catch((error) => {

    //     });
    // }


    return (
        <div className="container-fluid mt-5">
            {/* <h1 className="h3 mb-2 text-gray-800"> Customer </h1> */}

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"> Customer Details </h6>
                </div>
                <div className="card-body">
                    <div>
                        {/* <div className="row">
                            <div className="col">
                                <label htmlFor=""> Name </label>
                                <label htmlFor=""> ABCD </label>
                            </div>
                        </div> */}

                        <table className="table pending-appication-table">
                            <tr>
                                <th border="0"> Title </th>
                                <td> {customer?.title} </td>

                                <th border="0"> First Name </th>
                                <td> {customer?.first_name} </td>

                                <th border="0"> Last Name </th>
                                <td> {customer?.last_name} </td>
                            </tr>
                            <tr>
                                
                                <th> Email </th>
                                <td> {customer?.email} </td>
                                <th> Company Name </th>
                                <td> {customer?.company_name} </td>
                                <th> Status </th>
                                <td> {customer?.status} </td>
                            </tr>

                            <tr>
                                <th> Address </th>
                                <td> {customer?.address} </td>
                                <th> City </th>
                                <td> {customer?.city} </td>
                                <th> State </th>
                                <td> {customer?.state?.name} </td>
                            </tr>
                            
                            <tr>
                                <th> Country </th>
                                <td> {customer?.country?.name} </td>
                                <th> Pincode </th>
                                <td> {customer?.pincode} </td>
                                <th> Phone </th>
                                <td> {customer?.phone} </td>
                            </tr>
                            
                            <tr>
                                <th> Tax ID </th>
                                <td> {customer?.tax_id} </td>
                            </tr>
                        </table>
                    </div>
                    {/* <hr /> */}
                </div>
            </div>
        </div>
    );
}


export default ViewCustomer;
